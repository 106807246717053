<template>
  <div>
    <div class="container-fluid" v-if="searchClient !== false">
      <ais-instant-search
        :search-client="searchClient"
        :index-name="searchIndex"
      >
        <div class="row">
          <div class="col">
            <div class="card card-shadow">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="form-group mb-sm-0">
                          <ais-search-box>
                            <div
                              slot-scope="{
                                currentRefinement,
                                isSearchStalled,
                                refine
                              }"
                            >
                              <b-input-group>
                                <input
                                  type="search"
                                  :value="currentRefinement"
                                  @input="refine($event.currentTarget.value)"
                                  placeholder="Search"
                                  class="form-control"
                                />
                                <template #append>
                                  <ais-refinement-list
                                    attribute="tags"
                                    searchable
                                    show-more
                                  >
                                    <b-dropdown
                                      text="Filter"
                                      variant="outline-secondary"
                                      slot-scope="{
                                        items,
                                        isShowingMore,
                                        isFromSearch,
                                        canToggleShowMore,
                                        refine,
                                        toggleShowMore,
                                        searchForItems
                                      }"
                                      toggle-class="no-left-radius"
                                      menu-class="filter-menu"
                                    >
                                      <b-dropdown-form>
                                        <input
                                          @input="
                                            searchForItems(
                                              $event.currentTarget.value
                                            )
                                          "
                                          placeholder="Search for tags..."
                                          class="form-control"
                                        />
                                      </b-dropdown-form>
                                      <b-dropdown-form>
                                        <ul class="list-style-none pl-0">
                                          <li
                                            v-if="isFromSearch && !items.length"
                                          >
                                            No results.
                                          </li>
                                          <li
                                            v-for="item in items"
                                            :key="item.value"
                                          >
                                            <b-form-checkbox
                                              :checked="item.isRefined"
                                              @input="refine(item.value)"
                                              :style="{
                                                fontWeight: item.isRefined
                                                  ? 'bold'
                                                  : ''
                                              }"
                                            >
                                              <div>
                                                <ais-highlight
                                                  attribute="item"
                                                  :hit="item"
                                                />
                                                <b-badge class="ml-2">{{
                                                  item.count.toLocaleString()
                                                }}</b-badge>
                                              </div>
                                            </b-form-checkbox>
                                          </li>
                                        </ul>
                                      </b-dropdown-form>
                                      <b-dropdown-item-button
                                        @click.stop="toggleShowMore"
                                        :disabled="!canToggleShowMore"
                                        class="text-center"
                                        >{{
                                          !isShowingMore
                                            ? "Show more"
                                            : "Show less"
                                        }}
                                      </b-dropdown-item-button>
                                    </b-dropdown>
                                  </ais-refinement-list>
                                </template>
                              </b-input-group>
                              <span :hidden="!isSearchStalled">Loading...</span>
                            </div>
                          </ais-search-box>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-8">
                        <div class="float-right" v-if="isAuthenticated">
                          <b-button
                            :to="{ name: 'recipe-new' }"
                            variant="outline-primary"
                            >New Recipe</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <infinite-hits>
          <div
            slot-scope="{
              items,
              refinePrevious,
              refineNext,
              isFirstPage,
              isLastPage
            }"
          >
            <div class="row">
              <div class="col text-center my-3" v-if="!isFirstPage">
                <b-button @click="refinePrevious">
                  Show previous results
                </b-button>
              </div>
            </div>
            <div class="row recipe-row">
              <recipe-card
                v-for="recipe in items"
                :recipe="recipe"
                :key="recipe['.key']"
              ></recipe-card>
            </div>
            <div class="row">
              <div class="col text-center my-3">
                <b-button @click="refineNext" v-if="!isLastPage" class="mx-1">
                  Show more results
                </b-button>
              </div>
            </div>
          </div>
        </infinite-hits>

        <ais-powered-by />
      </ais-instant-search>
    </div>
    <div class="container-fluid" v-if="searchClient === false">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import RecipeCard from "@/components/RecipeCard";
import CustomInfiniteHits from "@/components/CustomInfiniteHits";
import Loader from "@/components/Loader";
import algoliasearch from "algoliasearch/lite";

export default {
  name: "Recipes",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    "recipe-card": RecipeCard,
    "infinite-hits": CustomInfiniteHits,
    loader: Loader
  },
  data() {
    return {
      bind: [],
      searchClient: false
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
    searchIndex() {
      return window.environment === "production"
        ? "prod_Recipes"
        : "sandbox_Recipes";
    },
    searchKey() {
      return this.searchConfig.search_key;
    }
  },
  watch: {
    searchKey: {
      immediate: true,
      handler() {
        const config = this.searchConfig;
        if (config && "app_id" in config && config.app_id !== "") {
          // Initialize Algolia
          this.searchClient = algoliasearch(config.app_id, config.search_key);
        }
      }
    }
  },
  methods: {},
  created() {
    this.page = this.$route.params.page ? this.$route.params.page : 1;
    this.selectedTags = this.$route.query.tags
      ? this.$route.query.tags.split(",")
      : [];
  }
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.recipe-card-body-text {
  height: 130px;
  overflow: hidden;
  margin-bottom: 10px;
}

.recipe-row {
  padding-top: 4px;
  padding-left: 9px;
  padding-right: 9px;
}

.recipe-card {
  margin: 0;
  padding: 5px;
  min-width: 20%;
}

.card-img-top {
  height: 150px;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-link {
  color: rgb(255, 152, 0);
  font-weight: 500;
}

#recipe-tags {
  margin-left: 0px;
}

.recipe-tag {
  margin-left: 5px;
}

.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #424242;
}

.chip img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.chip-sm {
  display: inline-block;
  padding: 0;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
}

.chip-sm img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.gold-star {
  color: gold;
  font-size: 1.2em;
}

#recipe-carousel {
  height: 300px;
  max-width: 600px;
  background-color: #424242;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

#recipe-carousel .carousel-inner {
  height: inherit;
  max-width: inherit;
}

.recipe-carousel-image {
  object-fit: contain;
  object-position: 50% 50%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.form-group {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.form-inline .form-group {
  margin-left: 0;
}

#recipe-edit-tags .custom-select {
  width: auto;
}

.recipe-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}

.ordered-list-group {
  list-style: decimal inside;
}

.ordered-list-group-item {
  display: list-item;
}

#ingredients-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#recipe-ingredients-list .btn-outline-secondary {
  color: #495057;
  background-color: white;
  background-image: none;
  border-color: #ced4da;
}

#ingredients th {
  border-top: 0;
}

.ingredients-amount-col {
  min-width: 285px;
}

.recipe-ingredients-amount {
  max-width: 100px;
  float: left;
}

.recipe-ingredients-unit {
  max-width: 185px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#instructions-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#instructions .list-group-item {
  border: 0;
}

#recipe-instructions-list {
  list-style-type: none;
}

#recipe-instructions-list > li {
  min-height: 108px;
}

#recipe-instructions-list .row {
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

#recipe-instructions-list .row:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: 22px;
}

#recipe-instructions-list .row:last-of-type {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-bottom: 22px;
}

.col-ingredient-handle {
  width: 30px;
  margin-right: 5px;
  padding-top: 7px;
}

.col-ingredient-desc {
  width: calc(100% - 35px);
}

.col-ingredient-delete {
  width: 72px;
  margin-left: 5px;
}

.recipe-instructions-numeral {
  float: left;
  height: 38px;
  padding-top: 8px;
}

.recipe-instructions-textarea {
  height: 100px;
  width: 100%;
}

#images-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

.explain-text {
  font-style: italic;
  margin-top: 5px;
  margin-left: 10px;
}

.recipe-sharing-row {
  margin-top: 15px;
}

#sharing-family-rows {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.list-style-none {
  list-style: none;
}

.checkbox-text-wrapper {
  width: 100%;
  text-align: left;
  cursor: pointer;
}
</style>

<style>
.no-left-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.filter-menu {
  min-width: 200px;
}
</style>
