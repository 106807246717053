var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.searchClient !== false)?_c('div',{staticClass:"container-fluid"},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.searchIndex}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card card-shadow"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-4"},[_c('div',{staticClass:"form-group mb-sm-0"},[_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var currentRefinement = ref.currentRefinement;
                            var isSearchStalled = ref.isSearchStalled;
                            var refine = ref.refine;
return _c('div',{},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('ais-refinement-list',{attrs:{"attribute":"tags","searchable":"","show-more":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var items = ref.items;
                                    var isShowingMore = ref.isShowingMore;
                                    var isFromSearch = ref.isFromSearch;
                                    var canToggleShowMore = ref.canToggleShowMore;
                                    var refine = ref.refine;
                                    var toggleShowMore = ref.toggleShowMore;
                                    var searchForItems = ref.searchForItems;
return _c('b-dropdown',{attrs:{"text":"Filter","variant":"outline-secondary","toggle-class":"no-left-radius","menu-class":"filter-menu"}},[_c('b-dropdown-form',[_c('input',{staticClass:"form-control",attrs:{"placeholder":"Search for tags..."},on:{"input":function($event){return searchForItems(
                                            $event.currentTarget.value
                                          )}}})]),_c('b-dropdown-form',[_c('ul',{staticClass:"list-style-none pl-0"},[(isFromSearch && !items.length)?_c('li',[_vm._v(" No results. ")]):_vm._e(),_vm._l((items),function(item){return _c('li',{key:item.value},[_c('b-form-checkbox',{style:({
                                              fontWeight: item.isRefined
                                                ? 'bold'
                                                : ''
                                            }),attrs:{"checked":item.isRefined},on:{"input":function($event){return refine(item.value)}}},[_c('div',[_c('ais-highlight',{attrs:{"attribute":"item","hit":item}}),_c('b-badge',{staticClass:"ml-2"},[_vm._v(_vm._s(item.count.toLocaleString()))])],1)])],1)})],2)]),_c('b-dropdown-item-button',{staticClass:"text-center",attrs:{"disabled":!canToggleShowMore},on:{"click":function($event){$event.stopPropagation();return toggleShowMore($event)}}},[_vm._v(_vm._s(!isShowingMore ? "Show more" : "Show less")+" ")])],1)}}],null,true)})]},proxy:true}],null,true)},[_c('input',{staticClass:"form-control",attrs:{"type":"search","placeholder":"Search"},domProps:{"value":currentRefinement},on:{"input":function($event){return refine($event.currentTarget.value)}}})]),_c('span',{attrs:{"hidden":!isSearchStalled}},[_vm._v("Loading...")])],1)}}],null,false,1924321798)})],1)]),_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-8"},[(_vm.isAuthenticated)?_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"to":{ name: 'recipe-new' },"variant":"outline-primary"}},[_vm._v("New Recipe")])],1):_vm._e()])])])])])])])]),_c('infinite-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var items = ref.items;
          var refinePrevious = ref.refinePrevious;
          var refineNext = ref.refineNext;
          var isFirstPage = ref.isFirstPage;
          var isLastPage = ref.isLastPage;
return _c('div',{},[_c('div',{staticClass:"row"},[(!isFirstPage)?_c('div',{staticClass:"col text-center my-3"},[_c('b-button',{on:{"click":refinePrevious}},[_vm._v(" Show previous results ")])],1):_vm._e()]),_c('div',{staticClass:"row recipe-row"},_vm._l((items),function(recipe){return _c('recipe-card',{key:recipe['.key'],attrs:{"recipe":recipe}})}),1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center my-3"},[(!isLastPage)?_c('b-button',{staticClass:"mx-1",on:{"click":refineNext}},[_vm._v(" Show more results ")]):_vm._e()],1)])])}}],null,false,3376128355)}),_c('ais-powered-by')],1)],1):_vm._e(),(_vm.searchClient === false)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center"},[_c('loader',{staticClass:"my-5",attrs:{"color":"rgba(255, 152, 0, 0.75)"}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }